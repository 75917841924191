import { ChangeTypeEnum } from '@/resource/enum/changeType';
import { ProductionOrderChangeDetailResource, ProductionOrderChangeResource } from '@/resource/model';
import { PagingMixin } from './../../../../mixins/paging';
import { mixins } from 'vue-class-component';

import { Component } from 'vue-property-decorator';
import productionOrderChangeService from '@/api/production-management/production-order-change';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import {
  OrderChangeStatusEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { messageError, translation } from '@/utils';
import { Form } from 'element-ui';

@Component({
  components: {}
})
export default class ProductionOrderChangeDetail extends mixins(PagingMixin) {
  /**
   * 审批弹窗显示控制
   */
  public approvalDialogVisible = false;

  /**
   * 审核
   */
  public btnApprovalLoading = false;

  /**
   * 审核表单对象
   */
  public formData: {
    auditDescription: string;
  } = {
    auditDescription: ''
  };

  /**
   * 审批表单数据对象
   */
  public formRules = {
    auditDescription: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.inputApprovalDescription')));
            return;
          }

          callback();
        }
      }
    ]
  };

  /**
   * 单据ID
   */
  public id: number | null = null;

  /**
   * 单据基本信息
   */
  public orderChange: ProductionOrderChangeResource | null = null;

  /**
   * 变更明细
   */
  public detailList: Array<ProductionOrderChangeDetailResource> = [];

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderChangeDetailResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini'
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderChangeDetailResource>> = [
    {
      prop: 'orderItemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true,
      formatter: (row: Object): any => {
        const detail = row as ProductionOrderChangeDetailResource;

        return detail.orderItemCode;
      }
    },
    {
      prop: 'type',
      label: 'orderChangeDetail.changeType',
      minWidth: '120px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleSize',
      label: 'orderProduct.visibleSize',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return `${(rowData as ProductionOrderChangeDetailResource).visibleWidth} × ${
          (rowData as ProductionOrderChangeDetailResource).visibleHeight
        }`;
      }
    },
    {
      prop: 'completeSize',
      label: 'orderProduct.completeSize',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return `${(rowData as ProductionOrderChangeDetailResource).finishWidth} × ${
          (rowData as ProductionOrderChangeDetailResource).finishHeight
        }`;
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'orderProduct.prepressCrafts',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProductionOrderChangeDetailResource).prepressCrafts) {
          return '--';
        }
        if ((row as ProductionOrderChangeDetailResource).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${
              PrepressChangeColorCraftsEnum[(row as ProductionOrderChangeDetailResource).prepressCrafts]
            }`
          );
        }
        return translation(
          `prepressLayoutCrafts.${
            PrepressLayoutCraftsEnum[(row as ProductionOrderChangeDetailResource).prepressCrafts]
          }`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressFlag',
      label: 'orderProduct.prepressFlag',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'printingFlag',
      label: 'orderProduct.printingFlag',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendFlag',
      label: 'orderProduct.backendFlag',
      minWidth: '120px',
      showOverflowTooltip: true
    }
  ];

  public convertFlag(flag: number): string {
    return flag === 1 ? 'el-icon-check' : 'el-icon-close';
  }

  public created(): void {
    this.id = Number(this.$route.query?.id);
    this.initReceiptData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getChangeTypeI18Key(type: ChangeTypeEnum): string {
    return productionOrderChangeService.getChangeTypeI18Key(type);
  }

  /**
   * 返回
   */
  public goBack(): void {
    this.$router.go(-1);
  }

  /**
   * 重置审批表单
   */
  public resetApprovalForm(): void {
    this.btnApprovalLoading = false;
    this.approvalDialogVisible = false;
    this.formData = {
      auditDescription: ''
    };

    (this.$refs.approvalForm as Form).resetFields();
  }

  /**
   * 审核按钮
   */
  public handleApproval(): void {
    this.approvalDialogVisible = true;
  }

  /**
   * 初始化单据数据
   */
  public initReceiptData(): void {
    productionOrderChangeService
      .getById(Number(this.id))
      .then(res => {
        this.orderChange = res;
        this.tableOption.data = res.orderChangeItemVOList;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
